import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTAButton from "../components/cta-button"
import Awards from "../components/awards"
import ImgHomeHero from "../img/hero"
import ImgFeaturedTelcowin from "../img/featured-telcowin"
import ImgFeaturedSuravont from "../img/featured-suravont"
import ImgFeaturedSCR from "../img/featured-scr"
import ImgFeaturedLVP from "../img/featured-lvp"
import ImgClientLogos1 from "../img/client-logos-1"
import ImgClientLogos2 from "../img/client-logos-2"
import ImgCoffee from "../img/coffee"
import IconExternalLink from "../icons/icon-externallink"
import IconChartDown from "../icons/icon-chartdown"
import IconCheck from "../icons/icon-check"

const IndexPage = () => (
  <Layout>
    <SEO
    title="Nadzim.com - World-class Custom Business Websites"
    description="Get new, high-value customers online continuously with a world-class website. I am a freelance web designer and developer based in Kuala Lumpur"/>
    <div className="cols-1-1" id="home-atf">
      <div id="home-intro">
        <h1>Get new, high-value customers online continuously with a world-class website.</h1>
        <p style={{marginTop: `2em`}}>I’m <strong>Nadzim Al-Rash</strong>, a freelance web designer from Kuala Lumpur. I help <strong>companies and entrepreneurs</strong> elevate the <strong>desirability</strong> of their brand.</p>
        <CTAButton url="/packages">Find out how</CTAButton>
      </div>
      <div id="home-hero">
        <ImgHomeHero/>
      </div>
    </div>
    <Awards/>
    <div className="cols-1-1" style={{ padding: `2em 0`}}>
      <div>
        <h3 className="h3-on-h1">Featured works</h3>
        <h1>Cultivating win-win relationships.</h1>
        <p>I’m proud to team up with the brilliant minds behind these exciting businesses.</p>
      </div>
    </div>
    <div className="section-highlight-bottom">
      <div className="cols-2-1" style={{ paddingTop: `3em`}}>
        <div>
        <a href="https://telcowin.com" target="_blank" rel="noopener noreferrer"><ImgFeaturedTelcowin/></a>
        </div>
      </div>
    </div>
    <div className="section-highlight">
      <div className="cols-2-1" style={{ paddingBottom: `3em`}}>
        <div>
          <h2>Telcowin</h2>
            <div>
              <p>A marketing website for Malaysia's leading Salesforce and technology partner. Increased leads for a diverse range of new services being offered.</p>
              <ul class="has-inline-svg">
                <li><IconExternalLink/><p><a href="https://telcowin.com" target="_blank" rel="noopener noreferrer">Visit telcowin.com</a></p></li>
              </ul>
            </div>
        </div>
        <div></div>
      </div>
      <div className="cols-1-2" style={{ padding: `3em 0`}}>
        <div></div>
        <div>
        <a href="https://lvpempire.com" target="_blank" rel="noopener noreferrer"><ImgFeaturedLVP/></a>
          <h2>LVP Empire Studio</h2>
          <div className="sub-cols-1-1">
            <div>
              <p>A minimalist portfolio for an award-winning celebrity wedding photographer. When the website was launched, almost all weekends for the year got fully booked.</p>
              <ul class="has-inline-svg">
              <li><IconExternalLink/><p><a href="https://lvpempire.com" target="_blank" rel="noopener noreferrer">Visit lvpempire.com</a></p></li>
              </ul>
            </div>
            <div>
              <div className="quote">Nadzim's user friendly design helps my clients to easily make bookings. He also continues to monitor my website and suggests improvements so my brand stays competitive.</div>
              <p>— Helmi Johari</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cols-2-1" style={{ padding: `3em 0`}}>
        <div>
        <a href="https://www.scrcompressor.com.my" target="_blank" rel="noopener noreferrer"><ImgFeaturedSCR/></a>
          <h2>SCR Engineering and Services</h2>
          <div className="sub-cols-1-1">
            <div>
              <p>A corporate website to showcase a range of compressors and filtration products. The website helped them gain more international inquiries, especially from USA.</p>
              <ul class="has-inline-svg">
                <li><IconExternalLink/><p><a href="https://www.scrcompressor.com.my" target="_blank" rel="noopener noreferrer">Visit scrcompressor.com.my</a></p></li>
              </ul>
            </div>
            <div>
              <div className="quote">Was being guided step by step on how to create a business website that is custom made for our company. And that allows us to broaden our brand awareness and also maintain potential.</div>
              <p>— Veronica Tan</p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div className="section-highlight-top">
      <div className="cols-1-2" style={{ paddingTop: `3em`}}>
        <div></div>
      <a href="https://www.suravont.com" target="_blank" rel="noopener noreferrer"><ImgFeaturedSuravont/></a>
      </div>
    </div>
    <div className="cols-1-2" style={{ paddingBottom: `3em`}}>
    <div></div>
      <div>
        <h2>Suravont</h2>
        <div className="sub-cols-1-1">
          <div>
            <p>A seamless online store selling artisan soaks, body scrubs, and cold-pressed soaps  to boost their online sales. I also provided professional product photography services.</p>
            <ul class="has-inline-svg">
              <li><IconExternalLink/><p><a href="https://www.suravont.com" target="_blank" rel="noopener noreferrer">Visit suravont.com</a></p></li>
            </ul>
          </div>
          <div>
          <div className="quote">It was an overall great working experience. Nadzim is professional, efficient, helpful, and keep up with marketing trends that elevates branding. We would highly recommend his expertise.</div>
            <p>— Amanda Sura</p>
          </div>
        </div>
      </div>
    </div>





    <div style={{textAlign: `center`, margin: `10em 0`}}>
      <h2 style={{margin: `2em auto`}}>More businesses I've worked with</h2>
      <div className="cols-1-1" id="client-logos">
        <ImgClientLogos1/>
        <ImgClientLogos2/>
      </div>
    </div>
    <div className="cols-1-1">
      <div></div>
      <div class="section">
        <h3 className="h3-on-h1">Sad but true</h3>
        <h1>Businesses lose opportunities with poorly-made websites.</h1>
        <p>More and more potential customers are looking for your products and services online. Nowadays, your website is the ambassador of your business.</p>
        <p>Sales opportunities are lost when a website:
          <ul class="has-inline-svg">
            <li><IconChartDown/>is not specifically designed to solve actual customer needs.</li>
            <li><IconChartDown/>does not convey professionalism and trustworthiness.</li>
            <li><IconChartDown/>is made by someone who doesn’t give a damn about the business.</li>
          </ul>
        </p>
        <p><strong>You don’t have to go through this.</strong></p>
      </div>
    </div>
    <div className="cols-2-1 section">
      <ImgCoffee/>
    </div>

    <div className="cols-1-1">
      <div class="section">
        <h3 className="h3-on-h1">Welcome to Nadzim.com</h3>
        <h1>Let's do websites better.</h1>
        <ul class="has-inline-svg">
          <li><IconCheck/><strong>I design growth-driven, high-quality websites that bring results.</strong></li>
        </ul>
        <p>I want to create your dream website you’d be proud to call yours. Let’s work together to deliver an amazing web experience that gets you better, high-value customers over and over.
        </p>
        <CTAButton url="/packages">View Pricing</CTAButton>
      </div>
    </div>
  </Layout>
)

export default IndexPage
